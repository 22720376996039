.button--flex {
    display: inline-flex;
    align-items: center;
}

.qualification__container {
    max-width: 768px;
}
.qualification__tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}
.qualification__button {
    font-size: 1.25rem;
    font-weight: 500;
    color:var(--color-light);
    margin: 1rem;
    cursor: pointer;
}
.qualification__button:hover {
    color: var(--color-bg-variant);
}

.qualification__icon {
    font-size: 1.8rem;
    margin-right: 0.25rem;
}
.qualification__icon-calender {
    margin-right: 0.25rem;
}
.qualification__active {
    color: var(--color-primary);
}
.qualification__sections {
    display: grid;
    grid-template-columns: 0.5fr;
    justify-content: center;
}
.qualification__content {
    display:none;
}
.qualification__content-active {
    display: block;
}
.qualification__data {
    display:grid;
    grid-template-columns: 1fr max-content 1fr;
    column-gap: 1.5rem;
}
.qualification__title {
    font-size: 1rem;
    font-weight: 400;
}
.qualification__subtitle {
    display: inline-block;
    font-size: 0.875rem;
    margin-bottom: 1rem;
}
.qualification__calendar{
    font: size 0.875rem;
}
.qualification__rounder {
    display: inline-block;
    width: 13px;
    height: 13px;
    background-color: var(--color-light);
    border-radius: 50%;
}

.qualification__line{
    display: block;
    width: 1px;
    height: 100%;
    background-color: var(--color-light);
    transform: translate(6px,-7px);
}

/* For large devices */
@media screen and (max-width: 992px) {
    .qualification__container {
        margin-left: auto;
        margin-right: auto;
    }
}
    /* For medium devices */
    @media screen and (max-width: 768px) {
    .qualification__container {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }
}
@media screen and (max-width: 576px) {
    .qualification__sections {
        grid-template-columns: initial;
    }
    .qualification__button {
        margin: 0 0.75rem;
    }
}
    /* For small devices */
    @media screen and (max-width: 350px) {
    .qualification__data {
        gap: 0.5rem;
    }
}

